import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import styled from "styled-components"
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

export default class PostList extends React.Component {
  render() {
    const { posts, title } = this.props

    const PostCard = styled.div`
      border: 1px solid #ddd;
      background: #fff;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      transition: 300ms box-shadow;
      justify-content: center;
      min-height: 200px;

      &:hover, &:focus {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      }

      @media(min-width: 550px) {
        &.has-image {
          padding-left: calc(50% + 30px);
        }
      }
    `

    const Image = styled(Img)`
      height: 0;
      padding-bottom: 50%;
      margin-bottom: 20px;

      @media(min-width: 550px) {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        padding-bottom: 0;
        position: absolute !important;
      }
    `

    return (
      <section className="section">
        <div className="container">
          <div>
            <h1 className="title is-size-1 has-text-weight-bold is-bold-light has-text-centered m-b-lg">{title}</h1>
          </div>
          <div className="columns is-multiline">
            {posts.map(({ node: post }) => (
              <div
                className="column is-6"
                key={post.id}
              >
                <PostCard className={post.featured_media ? 'has-image' : ''}>
                  {post.featured_media && (
                    <Link to={`/${post.slug}`}>
                      <div className="imageContainer">
                        <Image fluid={post.featured_media.localFile.childImageSharp.fluid} />
                      </div>
                    </Link>
                  )}
                  <div>
                    <Link className='has-text-primary is-size-5' to={`/${post.slug}`}>
                      {post.title}
                    </Link>
                  </div>
                  <HTMLEllipsis
                    maxLine="5"
                    style={{ marginBottom: `20px` }}
                    unsafeHTML={post.excerpt.replace(/<div class="link-more.*/, '')}
                  />
                  <Link className="button is-small" to={`/${post.slug}`} style={{ alignSelf: `flex-start`, justifySelf: `flex-end` }}>
                    Keep Reading →
                  </Link>
                </PostCard>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    slug
  }
`
